import React from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"

const Wrapper = styled.div`
  width: 100%;
  background: #1b2a56;
  color: #fff;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
  margin: 0px auto;
  padding: 20px 0px;

  @media (max-width: 600px) {
    padding: 0px;
  }
`

const RowBox = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: 600px) {
    flex-direction: column-reverse;
  }
`

const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  flex-basis: 50%;

  @media (max-width: 600px) {
    flex-basis: 100%;
    padding: 0px;
  }
`

const TextWrapper = styled.div`
  width: 100%;

  @media (max-width: 600px) {
    padding: 40px 20px;
  }
`

const SectionTitle = styled.h2`
  font-size: 40px;
  font-weight: bold;
  font-family: "BebasNeueBold";
  line-height: 1;
  margin: 0px 0px 10px 0px;

  @media (max-width: 600px) {
    font-size: 36px;
  }
`

const ProductImageBox = styled.div`
  width: 100%;
  border-radius: 50px 0px;
  overflow: hidden;

  @media (max-width: 600px) {
    border-radius: 0px;
  }
`

const ProductImage = styled.img`
  width: 100%;
  margin: 0px;
  object-fit: contain;

  @media (max-width: 600px) {
    min-height: 300px;
    object-fit: cover;
  }
`

const ProductDescriptionBox = styled.div`
  width: 100%;

  p {
    margin: 0px 0px 5px;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
`

const Button = styled.a`
  position: relative;
  padding: 15px 30px;
  background: #bd2e24;
  border-radius: 25px 0px;
  color: #fff;
  font-family: "Museo";
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  margin: 0px;

  :hover {
    color: #fff;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`

const HotelsProfessionalSection = ({ intl, data }) => {
  return (
    data && (
      <Wrapper>
        <Container>
          <RowBox>
            <ColumnBox>
              <TextWrapper>
                {data.section_title && (
                  <SectionTitle
                    dangerouslySetInnerHTML={{
                      __html: data.section_title,
                    }}
                  />
                )}
                {data.section_text && (
                  <ProductDescriptionBox
                    dangerouslySetInnerHTML={{
                      __html: data.section_text,
                    }}
                  />
                )}

                {data.button_text && (
                  <ButtonWrapper>
                    <Button href="#hotels_top_section">
                      {data.button_text}
                    </Button>
                  </ButtonWrapper>
                )}
              </TextWrapper>
            </ColumnBox>
            <ColumnBox>
              {data.image && (
                <ProductImageBox>
                  <ProductImage
                    className="lazyload"
                    data-src={data.image}
                    alt={data.image_alt ? data.image_alt : ""}
                  />
                </ProductImageBox>
              )}
            </ColumnBox>
          </RowBox>
        </Container>
      </Wrapper>
    )
  )
}

export default injectIntl(HotelsProfessionalSection)
