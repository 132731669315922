import React from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"

const Wrapper = styled.div`
  width: 100%;
  background: #1b2a56;
  color: #fff;
  padding: 40px 20px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
  margin: 0px auto;
`

const SectionTitle = styled.h2`
  font-size: 40px;
  font-weight: bold;
  font-family: "BebasNeueBold";
  line-height: 1;
  margin: 0px 0px 10px 0px;
  text-align: center;

  @media (max-width: 600px) {
    font-size: 36px;
    text-align: left;
  }
`

const ServiceBoxWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

const ServiceBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-basis: calc(25% - 40px);
  background: #d7edf6;
  color: #000;
  margin: 20px;
  padding: 20px;
  border-radius: 20px;

  @media (max-width: 850px) {
    flex-basis: calc(50% - 40px);
  }

  @media (max-width: 600px) {
    min-width: 100%;
    max-width: 100%;
    flex-basis: 100%;
    margin: 25px 0px;
  }
`

const ServiceTitle = styled.h3`
  font-size: 24px;
  line-height: 1;
  font-family: "BebasNeueBold";
  font-weight: bold;
  margin: 0px 0px 10px;
  text-align: center;

  span {
    display: block;
  }
`

const ServiceSubTitle = styled.p`
  font-size: 14px;
  line-height: 1.2;
  font-family: "GothamLight";
  margin: 0px;
  text-align: center;
`

const ServiceIconImage = styled.img`
  width: 100%;
  max-width: 95px;
  margin: 0px 0px 15px;
  object-fit: contain;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
`

const Button = styled.a`
  position: relative;
  padding: 15px 30px;
  background: #bd2e24;
  border-radius: 25px 0px;
  color: #fff;
  font-family: "Museo";
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  margin: 0px;

  :hover {
    color: #fff;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`

const HotelsServiceSection = ({ intl, data }) => {
  return (
    data && (
      <Wrapper>
        <Container>
          {data.section_title && (
            <SectionTitle
              dangerouslySetInnerHTML={{
                __html: data.section_title,
              }}
            />
          )}
          {data.list && (
            <ServiceBoxWrapper>
              {data.list.map((item, index) => {
                return (
                  item && (
                    <ServiceBox to={item.button_url} key={index.toString()}>
                      {item.icon_logo && (
                        <ServiceIconImage
                          className="lazyload"
                          data-src={item.icon_logo}
                          alt={item.icon_logo_alt ? item.icon_logo_alt : ""}
                        />
                      )}
                      {item.title && (
                        <ServiceTitle
                          dangerouslySetInnerHTML={{
                            __html: item.title,
                          }}
                        />
                      )}
                      {item.text && (
                        <ServiceSubTitle
                          dangerouslySetInnerHTML={{
                            __html: item.text,
                          }}
                        />
                      )}
                    </ServiceBox>
                  )
                )
              })}
            </ServiceBoxWrapper>
          )}

          {data.button_text && (
            <ButtonWrapper>
              <Button href="#hotels_top_section">{data.button_text}</Button>
            </ButtonWrapper>
          )}
        </Container>
      </Wrapper>
    )
  )
}

export default injectIntl(HotelsServiceSection)
